<template>
  <div class="admin-create-report">
    <!-- Page header -->
    <div class="page-header">
      <h1 class="page-title">Admin - Run Report</h1>
      <div class="actions">
        <a-button class="button-margin-left btn-rounded" icon="arrow-left" size="large" type="primary"
          @click.prevent="goBack">Back</a-button>
      </div>
    </div>
    <!-- / Page header -->

    <!-- Form - Type -->
    <div class="form">
      <a-form-item label="Report Type">
        <a-select size="large" style="width: 100%" v-model="selectedReportType">
          <a-select-option v-for="(type, i) in reportTypes" :value="type.type" :key="i">
            {{ type.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </div>
    <!-- / Form - Type -->

    <!-- Form - Guidance Pack Heat Map -->
    <div class="form" v-if="selectedReportType ==
      'Soteria.Models.ReportCriteriaOrgGuidanceViewed, Soteria.Models'
      ">
      <!-- Loading guidance packs -->
      <div class="loader" v-if="isLoadingGuidancePacks">
        <a-spin></a-spin>
      </div>
      <!-- / Loading guidance packs -->

      <!-- Guidance packs -->
      <div v-if="!isLoadingGuidancePacks">
        <a-form-item label="Please select a guidance pack">
          <a-select style="width: 100%" size="large" v-model="selectedGuidancePackId">
            <a-select-option v-for="guidancePack in guidancePacks" :value="guidancePack['$v']['id']"
              :key="guidancePack['$v']['id']">
              {{ guidancePack["$v"]["displayName"] }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>
      <!-- / Guidance packs -->
    </div>
    <!-- / Form - Guidance Pack Heat Map -->

    <!-- Form - Incident Summary Report -->
    <div class="form" v-if="selectedReportType ==
      'Soteria.Models.ReportCriteriaOrgIncidentSummary, Soteria.Models'
      ">
      <a-row :gutter="10">
        <a-col :span="6">
          <a-form-item label="From">
            <a-date-picker style="width: 80%" size="large" v-model="from" />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="To">
            <a-date-picker style="width: 80%" size="large" v-model="to" />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="Incident Type">
            <a-select placeholder="All Incident Types" show-search option-filter-prop="children" v-model="incidentTypeId"
              size="large" style="width: 80%">
              <a-select-option :value="null">
                All Incident Types
              </a-select-option>
              <a-select-option :value="incidentType.id" v-for="(incidentType, i) in incidentTypes" :key="i">
                {{ incidentType.displayName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="Export As">
            <a-select placeholder="HTML" show-search option-filter-prop="children" v-model="exportAs" size="large"
              style="width: 80%">
              <a-select-option value="html">
                HTML
              </a-select-option>
              <a-select-option value="csv">
                CSV
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <div>
        <a-checkbox v-model="includeOpenIncidents" style="margin-right: 25px">
          Include Open Incidents
        </a-checkbox>

        <a-checkbox v-model="includeClosedIncidents">
          Include Closed Incidents
        </a-checkbox>
      </div>
    </div>
    <!-- / Form - Incident Summary Report -->

    <!-- Run report -->
    <div v-if="selectedReportType">
      <a-button :disabled="!canRunReport" @click.prevent="runReport" type="primary" size="large" class="btn-rounded">
        Run Report
      </a-button>
    </div>
    <!-- / Run report -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import organisations from "../../../api/organisations";
import reporting from "../../../api/reporting";
// const _ = require("lodash");

const REPORT_TYPES = [
  {
    type: "Soteria.Models.ReportCriteriaOrgGuidanceViewed, Soteria.Models",
    label: "Guidance Pack Heat Map",
  },
  {
    type: "Soteria.Models.ReportCriteriaOrgIncidentSummary, Soteria.Models",
    label: "Incident Summary Report",
  },
];

export default {
  data() {
    return {
      reportTypes: REPORT_TYPES,
      selectedReportType:
        "Soteria.Models.ReportCriteriaOrgGuidanceViewed, Soteria.Models",

      guidancePacks: [],
      isLoadingGuidancePacks: false,
      selectedGuidancePackId: null,

      from: window.moment(),
      to: window.moment(),
      incidentTypeId: null,
      includeOpenIncidents: true,
      includeClosedIncidents: true,

      exportAs: 'html',

      isSaving: false,
    };
  },

  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      myPresenceId: "myPresenceId",
      incidentTypes: "incidentTypes",
    }),

    canRunReport() {
      if (
        this.selectedReportType ==
        "Soteria.Models.ReportCriteriaOrgGuidanceViewed, Soteria.Models"
      ) {
        return this.selectedGuidancePackId !== null;
      }
      if (
        this.selectedReportType ==
        "Soteria.Models.ReportCriteriaOrgIncidentSummary, Soteria.Models"
      ) {
        return this.from && this.to;
      }
      return false;
    },
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    this.loadGuidancePacks();
  },

  methods: {
    ...mapActions("adminReporting", {
      pushReport: "pushReport",
    }),

    goBack() {
      this.$router.push("/admin/reporting");
    },

    loadGuidancePacks() {
      let vm = this;

      vm.isLoadingGuidancePacks = true;
      vm.guidancePacks = [];

      organisations
        .getGuidancePacksForOrg(this.tenantId, this.selectedOrganisation.id)
        .then((r) => {
          vm.isLoadingGuidancePacks = false;
          vm.guidancePacks = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.$message.error("Error loading guidance packs");
        });
    },

    runReport() {
      let vm = this;

      let reportRequest;
      if (
        this.selectedReportType ==
        "Soteria.Models.ReportCriteriaOrgGuidanceViewed, Soteria.Models"
      ) {
        reportRequest = {
          OwnerId: this.selectedOrganisation.id,
          CreatedBy: this.myPresenceId,
          Criteria: {
            $t: "Soteria.Models.ReportCriteriaOrgGuidanceViewed, Soteria.Models",
            $v: {
              title: "Guidance Pack Heat Map",
              guidancePackId: this.selectedGuidancePackId,
              orgId: this.selectedOrganisation.id,
              group: 1,
            },
          },
        };
      }

      if (
        this.selectedReportType ==
        "Soteria.Models.ReportCriteriaOrgIncidentSummary, Soteria.Models"
      ) {
        let incidentTypeIds = [];
        if (this.incidentTypeId) {
          incidentTypeIds = [this.incidentTypeId];
        }

        let dollarV = {
          title: "Incident Summary Report",
          orgId: this.selectedOrganisation.id,
          includeCurrent: this.includeOpenIncidents,
          includeArchived: this.includeClosedIncidents,
          startDate: window
            .moment(this.from)
            .clone()
            .startOf("day")
            .format("YYYY-MM-DD[T]HH:mm:ss.SSSSSSS[Z]"),
          endDate: window
            .moment(this.to)
            .clone()
            .endOf("day")
            .format("YYYY-MM-DD[T]HH:mm:ss.SSSSSSS[Z]"),
          // endDate: '2013-07-26T16:45:20.1234567Z',
          group: 1,
        };

        if (this.exportAs == 'csv') {
          dollarV['csv'] = true;
        }

        if (incidentTypeIds.length) {
          dollarV['incidentTypes'] = incidentTypeIds;
        }

        reportRequest = {
          OwnerId: this.selectedOrganisation.id,
          CreatedBy: this.myPresenceId,
          Criteria: {
            $t: "Soteria.Models.ReportCriteriaOrgIncidentSummary, Soteria.Models",
            $v: dollarV,
          },
        };
      }

      vm.isSaving = true;
      reporting
        .addReport(this.tenantId, reportRequest)
        .then((r) => {
          vm.pushReport(r.data);
          vm.isSaving = false;
          vm.$router.push("/admin/reporting?reset=false");
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$message.error("Error running report");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.admin-create-report {
  padding-bottom: 40px;

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
    margin-bottom: 20px;
  }

  .loader {
    text-align: center;
  }
}
</style>